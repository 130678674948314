<template>
	<div class="message_box LC_box">
		<div class="message_Tit LC_Header">
			系统通知
		</div>
		<div class="messageList" v-if="messageList.length">
			<div class="mgItem" @click="showMessage(item)" v-for="(item,index) in messageList" :key="index" :class="{Unread:item.count_num == 0}">
				{{item.not_title}}
				<span class="mg_time" v-if="item.not_pushtime">{{item.not_pushtime.substring(0,10)}}</span>
			</div>
			<div class="orderPaginat" v-if="total > pageSize">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :total="total"
				  :page-size="pageSize"
				  @current-change="changPage">
				</el-pagination>
			</div>
		</div>
		<div class="noData" v-else>
			<img src="@/views/images/noData.png" />
			<div class="noDataTips">
				<span>暂无数据</span>
			</div>
		</div>
		<el-dialog
		  title="通知详情"
		  :visible.sync="messageVisible"
		  width="800px"
		  custom-class="messageDetails"
		  :close-on-click-modal="false">
		  <div class="messageCon" v-html="messageCon"></div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
	    data() {
	      return {
				messageList:[],//消息列表
				messageVisible:false,//详情弹窗
				total:0,//总条数
				pageSize:8,//每页条数
				messageCon:"",//消息详情
				cuurpage:1,//当前页码
			}
		},
		methods: {
			//获取列表
			getmessageList(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/notice.html", {
					token:window.sessionStorage.getItem('token'),
					page:this.cuurpage,
					limit:this.pageSize,
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.total = response.data.data.total;
						this.messageList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//展示详情
			showMessage(item){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/noticeDetail.html", {
					token:window.sessionStorage.getItem('token'),
					ids:this.PublicJs.Encrypt(item.not_id)
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.messageVisible = true;
						this.messageCon = response.data.data.not_content;
						//获取列表
						this.getmessageList()
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//分页
			changPage(val){
				this.cuurpage = val
				//获取列表
				this.getmessageList()
			},
		},
		created() {
			//获取列表
			this.getmessageList()
		}
	}
</script>

<style scoped="scoped">
	.messageList{
		padding: 0 33px;
	}
	.mgItem{
		width: 100%;
		height: 74px;
		line-height: 74px;
		border-bottom: 1px solid #EEEEEE;
		position: relative;
		padding-right: 120px;
		font-size: 14px;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		cursor: pointer;
		color: #333;
	}
	.mgItem.Unread{
		background-image: url(../images/mg_list.png);
		background-repeat: no-repeat;
		background-position: center left;
		padding-left: 30px;
	}
	.mgItem:hover{
		color: #4E6FF2;
	}
	.mg_time{
		position: absolute;
		top: 0;
		right: 0;
		color: #999;
	}
	.messageCon{
		line-height: 24px;
	}
	.noData{
		text-align: center;
		width: 100%;
		height: 236px;
		margin-top: 153px;
	}
	.noDataTips{
		font-size: 16px;
		color: #333;
		margin-top: 25px;
	}
	.orderPaginat{
		padding: 10px 0;
		text-align: center;
	}
</style>
<style>
	.messageDetails .el-dialog__header{
		background-color: #4D6EF2;
		padding: 0;
		height: 50px;
		line-height: 50px;
		text-align: center;
	}
	.messageDetails .el-dialog__title{
		color: #fff;
		font-size: 16px;
	}
	.messageDetails .el-dialog__headerbtn .el-dialog__close{
		color: #FFF;
	}
	.messageDetails .el-dialog__headerbtn{
		top: 14px;
		font-size: 20px;
	}
	.messageDetails .el-dialog__body{
		height: 520px;
		padding: 20px;
		overflow: auto;
	}
</style>
